//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
$primary: #4279ee;
$secondary: #5e5873;
$danger: #e01f11;
// Font Family

$english_family: "Inter", "Noto Kufi Arabic", sans-serif;
$arabic_family: "Noto Kufi Arabic", "Inter", sans-serif;

// stylelint-enable value-keyword-case
$font-size-base: 0.75rem;

$h3-font-size: $font-size-base * 1.5; // 18px
$h4-font-size: $font-size-base * 1.25; // 15px
$h5-font-size: $font-size-base * 1.16; // 14px

$btn-white-space: nowrap;
$btn-padding-x: 1rem;
