.vertical-layout.vertical-menu-modern.menu-collapsed .app-content {
  margin-right: 80px;
  margin-left: 0;
}

.content {
  margin-left: 0;
  margin-right: 260px;
}

.header-navbar.fixed-top {
  left: 0;
  right: 260px;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top {
  right: 80px;
  left: 0;
}

select.form-control:not([multiple="multiple"]) {
  background-position: calc(100% - 3px) 13px, calc(100% - 20px) 13px, 100% 0;
}

.modal-slide-in .modal-dialog {
  right: auto;
  left: 0;
}

.toast__close {
  left: 15px;
  right: auto;
}

.modal-slide-in.fade .modal-dialog,
.modal-slide-in .modal.fade .modal-dialog {
  transform: translateX(-100%);
}

.navbar-container {
  margin-right: 0;
}

.navbar-nav {
  margin-left: 0 !important;
  margin-right: auto;
  padding-right: 0;
}

.header-navbar
  .navbar-container
  ul.navbar-nav
  li
  a.dropdown-user-link
  .user-nav {
  margin-right: 0;
  margin-left: 0.8rem;
}

.user-name {
  margin-left: 0 !important;
}

.menu-title {
  margin-right: 1.1rem;
}

.apexcharts-legend-marker {
  margin-right: 0;
  margin-left: 5px;
}

@media only screen and (max-width: 1200px) {
  .header-navbar.fixed-top {
    right: 0;
  }

  .content {
    margin-right: 0;
  }
}

.badge {
  margin: 0 1rem 0 0;
}

@media (min-width: 768px) {
  .offset-md-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .offset-md-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .offset-lg-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
}

.dropdown-menu-right {
  right: auto !important;
  left: 0 !important;
}

.dropdown-toggle::after {
  right: 0.3rem;
}

.ng-select .ng-arrow-wrapper {
  padding-left: 2.5rem;
}

// custom rtl for validation error
.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-left: calc(1.45em + 0.876rem) !important;
  padding-right: 1rem !important;
  background-position: left calc(0.3625em + 0.219rem) center;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  margin-left: 1rem;
}

#user-profile .profile-header .profile-img-container {
  right: 2.14rem;
  left: auto;
}

.toast .toast-close-button {
  left: 0;
  float: left;
  right: auto;
}

.page-item:first-child:not(.prev-item) .page-link {
  border-top-right-radius: 1.428rem;
  border-bottom-right-radius: 1.428rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child:not(.next-item) .page-link {
  border-top-left-radius: 1.428rem;
  border-bottom-left-radius: 1.428rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.apexcharts-tooltip-text-label {
  margin-right: 5px;
}

.fooder_menu > ul li ul li ul {
  left: 100%;
  transform: translate(0px);
  right: -100%;
}

.fooder_menu > ul li ul {
  left: 55%;
}

.navbar-brand {
  margin-right: 0;
  margin-left: 1rem;
}

.active_restaurant {
  border-right: 3px solid $primary;
  border-left: 0;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg {
  margin-right: 0;
}

.vertical-layout.vertical-menu-modern.menu-expanded
  .main-menu
  .navigation
  li.has-sub
  > a:after,
.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu.expanded
  .navigation
  li.has-sub
  > a:after,
.vertical-overlay-menu .main-menu .navigation li.has-sub > a:after {
  right: auto;
  left: 20px;
  transform: rotate(180deg);
}

.navigation li a svg {
  margin-right: 0;
}

.toast-error {
  border-width: 0 5px 0 0;
}

.toast-success {
  border-width: 0 5px 0 0;
}

.main-menu {
  border-right: none;
  border-left: 1px solid #ebe9f1;
}

.menu-header__icon {
  margin-right: 0;
  margin-left: 12px;
}

.sales-channels__sidebar {
  border-right: none;
  border-left: 1px solid #dee2e6;
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after,
.custom-radio .custom-control-label::before,
.custom-radio .custom-control-label::after {
  right: 0;
  margin-left: 10%;
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
  h5 {
    margin-right: 2em;
  }
}

.ngx-charts {
  direction: ltr;
}
