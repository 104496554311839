.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ar {
  background-image: url(../flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(../flags/1x1/ar.svg);
}

.flag-icon-us {
  background-image: url(../flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../flags/1x1/us.svg);
}

.flag-icon-sa {
  background-image: url(../flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(../flags/1x1/sa.svg);
}