/*
NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// add fout @font-face declarations
@import "../fonts/inter/interfont.scss";
@import "../fonts/noto-kufi-arabic/notokufi.scss";

/*
	Body Style
*/

body {
  font-family: $english_family;
  text-align: start;
}

.form-control-text[type="text"] {
  padding: 0.438rem 2.5rem 0.438rem 1rem;
}

.mxw-500 {
  max-width: 500px;
}

body[dir="rtl"] {
  font-family: $arabic_family;
  @import "./styles-rtl.scss";
}

/*
	Override some form styles
*/

.maxw-330 {
  max-width: 330px;
}

.custom-switch .custom-control-label::after {
  top: 5px;
}

ng-otp-input {
  direction: ltr;
}

label {
  font-weight: 500;

  span {
    color: $danger;
  }
}

.form-control {
  font-size: 0.875rem;
  border-color: #dee2e6;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  color: $primary;
}

.form-control[readonly]:focus {
  border-color: #dee2e6;
  box-shadow: none;
}

.form-control::placeholder {
  font-weight: 400;
  color: #b9b9c3;
}

.form-control[type="search"] {
  border: 1px solid #e9ecef;
}

.form-control[type="password"] {
  padding: 0.438rem 2.5rem 0.438rem 1rem;
}

.icon-group {
  border-color: #e9ecef;
  color: #b9b9c3;
}

select.form-control {
  color: #b9b9c3;
  border: 1px solid #e9ecef;
}

/*
	Override some styles for ng-select classes
*/
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #b9b9c3 !important;
  font-weight: 400 !important;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  padding: 5px 5px !important;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 11px !important;
}

.ng-select .ng-select-container {
  font-size: 0.875rem;
}

.ng-select .ng-select-container {
  border-color: #dee2e6 !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: transparent !important;
  color: #5e5873;
  border: 1px solid #4279ee !important;
  font-size: 0.75rem !important;
  border-radius: 5px !important;
}

.ng-select .ng-arrow-wrapper {
  height: 15px;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: transparent !important;
  color: $primary !important;
}

.dropdown-toggle::after {
  left: 0.3rem;
}

.btn-second {
  border: 1px solid #979797 !important;
  color: #979797;
  background-color: transparent;
}

.spinner-border-sm {
  width: 0.75rem;
  height: 0.75rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: #edf3ff !important;
  color: #5e5873 !important;
  font-size: 12px;
  font-weight: 400;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: $danger;
}

.truncate__lines--2 {
  -webkit-line-clamp: 2;
}

.truncate__custom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
}

.truncate__lines--1,
.truncate__lines--2 {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #d9d9d9;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}

.table thead th {
  padding: 10px 15px;
  height: 50px;
  color: #5e5873;
  border-color: #e9ecef;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}

.table tbody tr td {
  padding: 10px 15px;
  border-color: #e9ecef;
  height: 60px;
}

.table tbody tr {
  color: #5e5873;
  font-size: 14px;
  font-weight: 400;
}

.table-hover tbody tr:hover {
  color: #5e5873;
  background-color: #fcfcfc;
}

.semi-button {
  background-color: transparent;
  border: none;
  color: $primary;
}

.custom-error {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: $danger;
  margin: 5px 0;
  padding: 0;
}

.icon-lg {
  width: 20px;
  height: 20px;
}

.icon-md {
  width: 15px;
  height: 15px;
}

.icon-primary {
  stroke: $primary;
}

.icon-danger {
  stroke: $danger;
}

.icon-warning {
  stroke: $warning;
}

.icon-secondary {
  stroke: $secondary;
}

.icon-second {
  stroke: rgba($color: $secondary, $alpha: 0.7);
}

hr {
  margin: 0;
}

.loading-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
}

.header-match {
  min-height: 60px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.responsive_nav {
  flex-wrap: nowrap;
  width: 100%;
}

.responsive_nav .nav {
  align-items: center;
}

.responsive_nav {
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
}

.responsive .nav-link {
  white-space: nowrap;
  padding: 0;
}

.cursor-move {
  cursor: move;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.7);
  display: table;
  border: 1px solid #eee;
}

.cdk-drag-preview td {
  flex-grow: 4;
  font-size: 14px;
  padding: 4px;
  vertical-align: middle;
}

.cdk-drag-placeholder {
  background-color: #fff;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__radiomark {
    position: relative;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background: #dee2e6;
    border-radius: 100%;
  }
}

.radio__input:checked ~ .radio__radiomark {
  background: #fff;
  border: 1px solid #00a8f8;
}

.radio__radiomark::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: #00a8f8;
  border-radius: 100%;
  top: 2px;
  left: 2px;
  display: none;
}

label h5 {
  margin-bottom: 0;
}

// color picker styles
body {
  color-picker {
    position: relative;
  }

  .WA_settings {
    .color-picker {
      right: unset !important;
      left: calc(100% + 45px) !important;
      top: -12px !important;

      .arrow {
        top: 8px !important;
        left: -20px !important;
        border-width: 5px 10px !important;
        border-color: rgba(0, 0, 0, 0) #777 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
      }
    }
  }

  .qr_code {
    .color-picker {
      left: 45px !important;
      right: unset !important;
      top: -265px !important;

      .arrow {
        bottom: 8px !important;
        left: -20px !important;
        border-width: 5px 10px !important;
        border-color: rgba(0, 0, 0, 0) #777 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
      }
    }
  }

  &[dir="rtl"] {
    .WA_settings {
      .color-picker {
        left: unset !important;
        right: calc(100% + 45px) !important;
        top: -12px !important;

        .arrow {
          top: 7px !important;
          right: -21px !important;
          border-width: 5px 10px !important;
          border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #777 !important;
        }
      }
    }

    .qr_code {
      .color-picker {
        left: unset !important;
        right: 45px !important;
        top: -265px !important;

        .arrow {
          right: -21px !important;
          bottom: 8px !important;
          border-width: 5px 10px !important;
          border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #777 !important;
        }
      }
    }
  }
}

.whatsappChatBtn {
  background-color: #1ab153;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;

  &:hover {
    color: #fff;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.gap-1 {
  gap: 0.5rem;
}
