$bg-pre: #282c34;

pre {
  background-color: $bg-pre !important;
  border-radius: 0.3rem;

  code {
    font-family: monospace;
  }
}

.hljs {
  font-size: 1.15rem;
}

// Flag icons custom css
.flag-icon {
  background-size: cover !important;
}

// ng form validation error
.error {
  ~ .input-group-append {
    .input-group-text {
      border-color: $danger !important;
    }
  }
}

// ! collapse toggle on animation dark border-bottom
.collapse-shadow {
  .card {
    &.open {
      border-color: $border-color !important;
    }
  }
}
